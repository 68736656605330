@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap);
.counseling-plan-icon-menu {
    position: relative
}

.counseling-plan-icon-menu::after {
    content: 'CP';
    position: absolute;
    bottom: 0px;
    font-size: 10px;
    margin-left: 6px;
}
#zoom-range {
    background: #ffffff;
    /* background: linear-gradient(to right, #696CFF 0%, #696CFF 50%, #fff 50%, #fff 100%); */
    accent-color: #696CFF;
    border: solid 1px #696CFF;
    border-radius: 8px;
    color: #696CFF;
    outline: none;
    transition: #696CFF;
    -webkit-appearance: none;
  }

  .file-select {
    width: 100%;
    position: relative;
    display: inline-block;
    height: 30%;
    border: 1px dashed;
    border-color: #696CFF;
    border-radius: 30px;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .file-select::before {
    /* background-color: white; */
    color: #696CFF;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .file-select::after {
    content: '';
  }
  
  .file-select.active {
    background-color: #F2F2FF;
    border-color: #F2F2FF;
    content: '';
  }

  .file-select input[type="file"] {
    opacity: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
  
  }
  
  #dragSpan {
    position: absolute;
    color: #696CFF;
  }
  
  #dragImage {
    position: absolute;
  }
  
body {
    margin: 0;
    font-family: 'Inter', sans-serif !important;
    font-size: 1rem !important; 
}
#root{
    height: 100vh;
}
.cursor {
    cursor: pointer;
}
input[type=checkbox]
{
  /* Double-sized Checkboxes */ /* IE */ /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */ /* Opera */
  transform: scale(1.5);
  padding: 10px;
}

.stripe label {
    color: #6b7c93;
    font-weight: 300;
    letter-spacing: 0.025em;
    width: 100%;
  }
  
  .stripe button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background-color: #6772e5;
    text-decoration: none;
    transition: all 150ms ease;
    margin-top: 10px;
  }
  
  .stripe button:hover {
    color: #fff;
    cursor: pointer;
    background-color: #7795f8;
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
  
  .stripe input,.StripeElement {
    display: block;
    margin: 10px 0 10px 0;
    /* max-width: 500px; */
    width: 100% !important;
    height: 44px;
    padding: 10px 14px;
    font-size: 16px;
    font-weight: 400;
    border: 0;
    outline: 0;
    border-radius: 12px;
    background: #F0F4F8;
  }
  
  .stripe input::-webkit-input-placeholder {
    color: #aab7c4;
  }
  
  .stripe input:-ms-input-placeholder {
    color: #aab7c4;
  }
  
  .stripe input::placeholder {
    color: #aab7c4;
  }
  
  .stripe input:focus,
  .stripe .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
      rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    transition: all 150ms ease;
  }
  
  .stripe .StripeElement.IdealBankElement,
  .stripe .StripeElement.FpxBankElement,
  .stripe .StripeElement.PaymentRequestButton {
    padding: 0;
  }
  
  .stripe .StripeElement.PaymentRequestButton {
    height: 40px;
  }
  .accepted {
    border: 1px solid #DCE1E7;
    border-radius: 12px;
    padding-left: 10px;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .span-accep {
    color: #778699;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;
    margin-right: 10px;
  }
  .cardp {
    margin-left: 5px
  }
  .htext {
    margin-bottom: 30px;
    color: #2C3F58;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.1;
  }
  .colort {
    color: #778699;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
  }
  .text-c {
    color: #2C3F58;
    font-weight: 700;
  }
  .text-p {
    color: #2C3F58;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
  }
  .currentCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .fc-3xgLq {
    display: none;
  }
    

.znk-invoice-row .custom {
    padding-top: 0;
    padding-bottom: 0;
    max-height: 30px;
}
.znk-invoice-row .custom .draft {
    background-color: transparent;
}
@-webkit-keyframes fadeIn { 
    from { 
        opacity: 0; 
    } 
    to { 
        opacity: 1; 
    } 
}
@keyframes fadeIn { 
    from { 
        opacity: 0; 
    } 
    to { 
        opacity: 1; 
    } 
}
.tab-pane.active { 
    color: inherit;
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn; 
    -webkit-animation-duration: 300ms; 
            animation-duration: 300ms; 
    -webkit-animation-timing-function: linear; 
            animation-timing-function: linear; 
}


.color-be {
    border-color: #FF3E1D !important;
}
.color-bp {
    border-color: #81B023 !important;
}
.color-bc {
    border-color: #778699 !important;
}
.text-through {
    text-decoration: line-through;
}
.cursor {
    cursor: pointer;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.modal-scroll::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.modal-scroll {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.submit-score-skeleton {
    background-color: #bc5de5 !important; 
    border-radius: 10;
    background-image: linear-gradient(90deg, #c280de, #bc5de5, #c280de) !important
}
.react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  .react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 40px;
    height: 20px;
    background: #D7E1EA;
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
  }
  
  .react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 15px;
    height: 15px;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }
  
  .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  
  .react-switch-label:active .react-switch-button {
    width: 60px;
  }
@-webkit-keyframes cargando {
    0% {
        -webkit-transform: rotateZ(0deg) scale(1);
                transform: rotateZ(0deg) scale(1);
        /* transform: scale(1); */
    }
    50% {
        -webkit-transform: rotateZ(360deg) scale(1.1);
                transform: rotateZ(360deg) scale(1.1);
        /* transform: scale(1.1); */
    }
    100% {
        -webkit-transform: rotateZ(0deg) scale(1);
                transform: rotateZ(0deg) scale(1);
        /* transform: scale(1); */
    }
    
}
@keyframes cargando {
    0% {
        -webkit-transform: rotateZ(0deg) scale(1);
                transform: rotateZ(0deg) scale(1);
        /* transform: scale(1); */
    }
    50% {
        -webkit-transform: rotateZ(360deg) scale(1.1);
                transform: rotateZ(360deg) scale(1.1);
        /* transform: scale(1.1); */
    }
    100% {
        -webkit-transform: rotateZ(0deg) scale(1);
                transform: rotateZ(0deg) scale(1);
        /* transform: scale(1); */
    }
    
}
.mailchimp-icon-loading {
    -webkit-animation-name: cargando !important;
            animation-name: cargando !important; 
    -webkit-animation-duration: 2s !important; 
            animation-duration: 2s !important;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

.scale-hover {
    transition: .7s
}
.scale-hover:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
.modal-colored .modal-content .modal-header {
    border-radius: 10px !important;
}
.styles_lineH24__1L7-Z {
    line-height: 24px !important;
}

@-webkit-keyframes fadeIn { 
    from { 
        opacity: 0; 
    } 
    to { 
        opacity: 1; 
    } 
}
@keyframes fadeIn { 
    from { 
        opacity: 0; 
    } 
    to { 
        opacity: 1; 
    } 
}
.tab-pane.active { 
    -webkit-animation-name: fadeIn; 
            animation-name: fadeIn; 
    -webkit-animation-duration: 300ms; 
            animation-duration: 300ms; 
    -webkit-animation-timing-function: linear; 
            animation-timing-function: linear; 
}


#concentrationTextArea::-webkit-input-placeholder {
    color: #808080;
   }
#concentrationTextArea:-ms-input-placeholder {
    color: #808080;
   }
#concentrationTextArea::placeholder {
    color: #808080;
   }
@-webkit-keyframes fadeIn { 
    from { 
        opacity: 0; 
    } 
    to { 
        opacity: 1; 
    } 
}
@keyframes fadeIn { 
    from { 
        opacity: 0; 
    } 
    to { 
        opacity: 1; 
    } 
}
.tab-pane.active { 
    -webkit-animation-name: fadeIn; 
            animation-name: fadeIn; 
    -webkit-animation-duration: 300ms; 
            animation-duration: 300ms; 
    -webkit-animation-timing-function: linear; 
            animation-timing-function: linear; 
}




@-webkit-keyframes example {
    0%   {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes example {
    0%   {opacity: 0;}
    100% {opacity: 1;}
}

.appears-animation {
  -webkit-animation-name: appears;
          animation-name: appears;
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
}

.tab-pane.not-animation { 
    /* animation-name: fadeIn; 
    animation-duration: 300ms; 
    animation-timing-function: linear;  */
    -webkit-animation: none !important;
            animation: none !important;
}
.matchBlockEducatorFinder {
    background-color: #A7E52124 !important;
    border: 2px dashed #A7E521 !important;
}

.matchBlockEducatorFinder > span {
    color: #A7E521 !important
}

.unmatchBlockEducatorFinder {
    background-color: #FF3E1D24 !important;
    border: 2px dashed #FF3E1D !important;
}
.unmatchBlockEducatorFinder > span {
    color: #FF3E1D !important
}
.cursor {
    cursor: pointer;
}
.barra-cont {
    height: 8px;
    background-color: #F0F4F8;
    border-radius: 4px
}
.barra-porc {
    border-color: #FFD422;
    border-width: 8px;
    margin: 0;
    padding: 0;
    border-radius: 4px;
}
.barra-cont-flat {
    height: 8px;
    background-color: #F0F4F8;
    border-radius: 4px
}
.barra-porc-flat {
    border-color: #A7E521;
    border-width: 8px;
    margin: 0;
    padding: 0;
    border-radius: 4px;
}
.minHeight {
    min-height: 0px;
}


.searchBox {
    align-items: center;
    display: flex;
    flex-direction: row;
  }
  .searchBox input {
    width: 100%;
    height: 2.8rem;
    border: none;
    padding-left: 0rem;
  }
  
  .searchBox {
    border-bottom: none;
    padding-left: 1.2rem;
  }
  
  .searchBox input[type="text"] {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #e6ebee;
    background-color: #ffffff;
    /* background-image: url("../shared/images/dashboard/searchDark.png"); */
    background-position: 10px 10px;
    background-repeat: no-repeat;
    padding: 12px 20px 12px 40px;
    outline: 0;
    font-size: 13px;
    font-weight: normal;
    font-family: Proxima-Regular;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #707070;
  }
  .closeSearch{
    position: absolute;
      margin-left: -30px;
      margin-top: 11px;
      cursor: pointer;
      width: 18px;
      height: 18px;
  }
  .search {
    display: inline-block;
    margin: 12px 15px;
    padding: 6px 5px 4px;
    vertical-align: top;
  }
  .search:hover {
    cursor: pointer;
  }

  .hover-button .hover-button--on,
  .hover-button:hover .hover-button--off {
    display: none;
  }
  
  .hover-button:hover .hover-button--on {
    display: block;
    position: absolute;
    right: 13px;
    cursor: pointer;
  }

  .hover-button--off {
    display: none;
  }
.modal-content {
    width: 500px;
}
.back-primary {
    background-color: #696CFF;
    border-radius: 16;
    color: #FFFFFF;
}
.view {
    border: 1px solid #DCE1E7;
    border-radius: 12px;
    cursor: pointer
}
.h-auto {
    height: auto;
}
.h-fixed {
    height: 400px;
    overflow-y: scroll;
}
.botom-fixed {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
.new-message-content {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: fixed;
    width: -webkit-fill-available;
    transition: .7s;
}
.open-new-message {
    bottom: 80px;
    opacity: 1;
}


.close-new-message {
    bottom: -80px;
    opacity: 0;
}
.input-message {
    padding: 5% !important;
    border-radius: 12px 0 0 12px !important;
    border-right: 0 !important;
    font-size: 18px !important;
}

.group-text {
    background-color: #fff !important;
    border-radius: 0px 12px 12px 0px !important;
}

.group-addon {
    cursor: pointer;
}



@-webkit-keyframes fadeIn { 
    from { 
        opacity: 0; 
    } 
    to { 
        opacity: 1; 
    } 
}
@keyframes fadeIn { 
    from { 
        opacity: 0; 
    } 
    to { 
        opacity: 1; 
    } 
}
.tab-pane.active { 
    -webkit-animation-name: fadeIn; 
            animation-name: fadeIn; 
    -webkit-animation-duration: 300ms; 
            animation-duration: 300ms; 
    -webkit-animation-timing-function: linear; 
            animation-timing-function: linear; 
}
.lesson-attended {
    background-color: #f3fcd4;
    color: #84b222
}
.lesson-upcoming {
    background-color: #696cff;
    color: white;
}
.lesson-incomplete {
    background-color: #fff5cb;
    color: #E6AC00
}
.lesson-missed {
    background-color: #ffeeeb;
    color: #FF3E1D
}
.lesson-active {
    background-color: #35425b;
}
.lesson-scheduled {
    background-color: #72809d;
    color: white;
}

.gg-view-list {
    box-sizing: border-box;
    position: relative;
    display: block;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    -webkit-transform: scale(var(--ggs, 1.2));
            transform: scale(var(--ggs, 1.2));
    border: 2px solid;
    border-radius: 3px;
    width: 20px;
    height: 14px
}

.gg-view-list::after,
.gg-view-list::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 2px;
    height: 14px;
    top: -2px;
    left: 3px;
    background: currentColor
}

.gg-view-list::before {
    width: 20px;
    height: 2px;
    left: -2px;
    top: 2px;
    box-shadow: 0 4px 0
}

.apply-width-content > .modal-content {
    width: 810px !important;
}
.icodel {
    display: none;
}
.icodelhover {
    position: absolute;
    right: 0;
    margin-right: 10px;
}



.span-info-header {
    color: white;
    margin: 0;
    position: relative;
    display: inline-block;
    padding: 10px;
    border-radius: 5px;
    white-space: normal; /* Permite que el texto haga saltos de línea */
    word-wrap: break-word; /* Evita que las palabras largas se desborden */
    width: auto; /* Se adapta al contenido por defecto */
    max-width: 100%; /* No excede el ancho completo del contenedor */
    transition: all 0.3s ease-in-out;
}

/* Para textos largos, establece un ancho máximo */
.maxWidthContent {
    max-width: 350px;
    white-space: normal;
}

.maxWidthContent-350 {
    max-width: 350px;
}

.maxWidthContent-400 {
    max-width: 400px;
}

.maxWidthContent-500 {
    max-width: 500px;
}


.widget-card-header {
    position: -webkit-sticky;
    position: sticky; 
    top: 0; 
    background-color: white;
    z-index: 10
}


.widget-card-footer {
    position: -webkit-sticky;
    position: sticky; 
    bottom: 0; 
    background-color: white;
    z-index: 10
}
/* .widget-card-header:hover {
    z-index: 10
} */
.icon-alert-deadline::before{
    color: #ff0000;
    font-size: 1.5em;
    content: 'Deadline approaching';
    font-size: 9px;
    position: absolute;
    bottom: -15px;
    left: -7px;
    text-align: center;
    line-height: 12px;
}

.icon-alert-deadline {
    position: relative;
}

.badge {
    font-size: 9px;
    font-weight: bold;
    margin-left: 5px;
}

.bottom-modal {
    position: absolute;
    bottom: 0;
}
.no-animation {
    -webkit-animation: none !important;
            animation: none !important;
}
.dashboard-box {
    box-shadow: 10px 10px 27px -15px rgba(0,0,0,0.75);
    border-radius: 12px;
}

.progress-lesson-bar {
    /* min-width: 150px; */
    width: auto;
    height: 10px;
    border-radius: 12px;
    overflow: hidden;
    background-color: gray;
}



.progress-lesson-bar > div {
    transition: .6s;
    width: 0%;
    /* width: 45%; */
    /* animation: barTransition; */
    height: 10px;
    border-radius: 0px;
    background-color: orange;
}



.alert-notification {
    background-color: #2c3f58;
    color: white;
    position: absolute;
    padding: 0px 10px;
    border-radius: 12px;
    bottom: -10%;
    opacity: 0;
    transition: .6s;
    cursor: pointer
}

.alert-notification.show {
    bottom: 30px;
    z-index: 0;
    opacity: 1;
} 

/* .header-plan-field {
    cursor: pointer;
} */

.header-plan-field > svg {
    visibility: hidden;    
}

.header-plan-field:hover > svg {
    visibility: visible
}

.header-plan-field.disable-sort > svg {
    visibility: hidden;
    /* cursor: default     */

}

.header-plan-field.visible-icon > svg {
    visibility: visible;  
    /* cursor: pointer;   */
}



.timeline-content {
    min-height: auto;
    max-height: 500px;
    overflow-y: scroll;
    border-radius: 10px;
}
.payment-items-container {
    border-radius: 10px;
    background-color: rgb(240, 244, 248);
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.payment-items-container > .payment-items-list {
    min-height: 250px;
    max-height: 250px;
    overflow-y: scroll;
}

.payment-items-container > .payment-items-collapse {
    align-self: flex-end;
}


.payment-items-content {
    min-height: 250px;
    max-height: 250px;
    overflow-y: scroll;
    border-radius: 10px;
    background-color: rgb(240, 244, 248);
    padding: 10px;
}
.timeline-content {
    min-height: auto;
    max-height: 500px;
    overflow-y: scroll;
    border-radius: 10px;
}
.paidOnTime {
    color: #92bb40;
}

.upcomming {
    color: #58677c;
}
.list-item-university {
    /* padding: 16px; */
    /* padding: 0 16px 16px 16px; */
    border-bottom: solid 1px #DEDEDE;
    cursor: pointer;
}

.list-item-university:hover {
    background-color: aliceblue;
    border-radius: 10px 10px 0px 0px;
}

.pagination-opacity {
    bottom: 0px;
    position: -webkit-sticky;
    position: sticky;
    background-image: linear-gradient(
        to bottom, 
        rgba(255, 255, 255, 0), 
        rgba(255, 255, 255, 0.95) 25%
    )
}

.text-hover:hover{
    color: #2d4059 !important;
    cursor: default;
}

.university-position {
    color: #A7E521 !important;
}

.general-university-information > div {
    margin-bottom: 2%;
}

.statistics-information > div {
    margin-bottom: 6%;
}

.primary-information {
    color: #aab7c4 !important;
    font-size: 0.75rem;
}

.title {
    color: #aab7c4;
    font-size: 13px;
}

.sub-title {
    font-size: 16px;
}

.statistics-information {
    /*border: solid;*/
}

.add-icon-university {
    border: solid;
    border-radius: 10px;
    height: 17px;
    width: 17px;
    background-color: white;
}

.circle {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    transition: all .3s ease;
}


.button-sticky {
  bottom: 0px;
  position: -webkit-sticky;
  position: sticky;
}

.file-select {
  width: 100%;
  position: relative;
  display: inline-block;
  height: 30%;
  border: 1px dashed;
  border-color: #696CFF;
  border-radius: 30px;
}

.file-select-tasks {
  width: 100%;
  position: relative;
  display: inline-block;
  height: 30%;
  border: 1px dashed;
  border-color: #696CFF;
  border-radius: 30px;
}

.file-select::before {
  /* background-color: white; */
  color: #696CFF;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.file-select::after {
  content: '';
}

.file-select.active {
  background-color: #F2F2FF;
  border-color: #F2F2FF;
  content: '';
}
.file-select-tasks.active {
  background-color: #F2F2FF;
  border-color: #F2F2FF;
  content: '';
}

.file-select input[type="file"] {
  opacity: 0;
  width: 100%;
  height: 100%;
  display: inline-block;

}

.file-select-tasks input[type="file"] {
  opacity: 0;
  width: 100%;
  height: 100%;
  display: inline-block;

}

#dragSpan {
  position: absolute;
  color: #696CFF;
}

#dragImage {
  position: absolute;
}

#src-file1::before {
  content: 'Seleccionar Archivo 1';
}

#src-file2::before {
  content: 'Seleccionar Archivo 2';
}

::-webkit-input-placeholder {
  color: #c4cad2;
}

:-ms-input-placeholder {
  color: #c4cad2;
}

::placeholder {
  color: #c4cad2;
} 
.form-switch {
    display: inline-block;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
  .form-switch i {
    position: relative;
    display: inline-block;
    margin-right: .5rem;
    width: 46px;
    height: 26px;
    background-color: #e6e6e6;
    border-radius: 23px;
    vertical-align: text-bottom;
    transition: all 0.3s linear;
  }
  .form-switch i::before {
    content: "";
    position: absolute;
    left: 0;
    width: 42px;
    height: 22px;
    background-color: #696CFF;
    border-radius: 11px;
    -webkit-transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
            transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
    transition: all 0.25s linear;
  }
  .form-switch i::after {
    content: "";
    position: absolute;
    left: 0;
    width: 22px;
    height: 22px;
    background-color: #fff;
    border-radius: 11px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
    -webkit-transform: translate3d(2px, 2px, 0);
            transform: translate3d(2px, 2px, 0);
    transition: all 0.2s ease-in-out;
  }
  .form-switch:active i::after {
    width: 28px;
    -webkit-transform: translate3d(2px, 2px, 0);
            transform: translate3d(2px, 2px, 0);
  }
  .form-switch:active input:checked + i::after { -webkit-transform: translate3d(16px, 2px, 0); transform: translate3d(16px, 2px, 0); }
  .form-switch input { display: none; }
  .form-switch input:checked + i { background-color: #696CFF; }
  .form-switch input:checked + i::before { -webkit-transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0); transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0); }
  .form-switch input:checked + i::after { -webkit-transform: translate3d(22px, 2px, 0); transform: translate3d(22px, 2px, 0); }

@-webkit-keyframes iconMove {
    0% {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
    30% {
        -webkit-transform: translateY(10px);
                transform: translateY(10px);
    }
    50% {
        -webkit-transform: translateY(15px);
                transform: translateY(15px);
    }
    100% {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
}

@keyframes iconMove {
    0% {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
    30% {
        -webkit-transform: translateY(10px);
                transform: translateY(10px);
    }
    50% {
        -webkit-transform: translateY(15px);
                transform: translateY(15px);
    }
    100% {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
}

@-webkit-keyframes shadowMove {
    0% {
        -webkit-transform: translateY(0px) scale(1);
                transform: translateY(0px) scale(1);
    }
    30% {
        -webkit-transform: translateY(-10px) scale(1.1);
                transform: translateY(-10px) scale(1.1);
    }
    50% {
        -webkit-transform: translateY(-15px) scale(1.3);
                transform: translateY(-15px) scale(1.3);
    }
    100% {
        -webkit-transform: translateY(0px) scale(1);
                transform: translateY(0px) scale(1);
    }
}

@keyframes shadowMove {
    0% {
        -webkit-transform: translateY(0px) scale(1);
                transform: translateY(0px) scale(1);
    }
    30% {
        -webkit-transform: translateY(-10px) scale(1.1);
                transform: translateY(-10px) scale(1.1);
    }
    50% {
        -webkit-transform: translateY(-15px) scale(1.3);
                transform: translateY(-15px) scale(1.3);
    }
    100% {
        -webkit-transform: translateY(0px) scale(1);
                transform: translateY(0px) scale(1);
    }
}

.icon-animation-rotate {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    transition: 2s;
    -webkit-animation: iconMove 2s infinite linear;
            animation: iconMove 2s infinite linear;

    -webkit-transform-style: preserve-3d;

            transform-style: preserve-3d; /* Mantiene la perspectiva durante la rotación */
    -webkit-transform-origin: center center;
            transform-origin: center center; /* Establece el punto de origen de la rotación en el centro del icono */
    /* filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.5)); */
    /* position: relative; */
}

.icon-animation-rotate::after {
    content: ""; /* Agrega contenido al pseudo-elemento */
    position: absolute; /* Posiciona el pseudo-elemento en relación al icono */
    bottom: -10px; /* Ajusta la posición vertical de la sombra */
    left: 30%; /* Ajusta la posición horizontal de la sombra */
    width: 40%; /* Ajusta el ancho de la sombra para que cubra el icono */
    height: 10px; /* Ajusta la altura de la sombra */
    border-radius: 50%;
    -webkit-animation: shadowMove 2s infinite linear;
            animation: shadowMove 2s infinite linear;
    background-color: rgba(0, 0, 0, 0.5); /* Configura el color de la sombra */
    /* transform: translateY(50%); Desplaza la sombra hacia abajo */
    -webkit-filter: blur(3px);
            filter: blur(3px)
  }
  
.horizontal-scroll {
    overflow-x: scroll;
    scrollbar-width: none;
    /* background-image: linear-gradient(
        to right, 
        rgba(255, 255, 255, 0.95) 25%,
        rgba(255, 255, 255, 0)
    );
    padding-right: 10px */
}
.horizontal-scroll::-webkit-scrollbar {
    display: none;
}
.blank_students {
    border: 1px dashed #778699;
    border-radius: 10px;
    padding-right: 25%;
    padding-left: 25%;
    padding-top: 15%;
    padding-bottom: 15%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; 
}
/* 
.left-gradient {
    background-image: linear-gradient(
        to right, 
        rgba(255, 255, 255, 0.95) 25%,
        rgba(0, 0, 0, 0)
    );
    padding-left: 10px;
} */
@-webkit-keyframes example {
    0%   {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes example {
    0%   {opacity: 0;}
    100% {opacity: 1;}
}

.appears-animation {
  -webkit-animation-name: appears;
          animation-name: appears;
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
}
.hidden {
  visibility: hidden;
  height: 0px;
}
