@keyframes example {
    0%   {opacity: 0;}
    100% {opacity: 1;}
}

.appears-animation {
  animation-name: appears;
  animation-duration: .3s;
}
.hidden {
  visibility: hidden;
  height: 0px;
}